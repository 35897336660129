<template>
  <div class="admin-smart-buttons">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/integration')"
          icon="left"
        ></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Integration - Smart Buttons
      </h1>
      <div class="actions">
        <a-button
          @click.prevent="loadSmartButtons"
          :disabled="isLoading"
          class="button-margin-left btn-rounded"
          icon="reload"
          size="large"
          type="default"
          >Refresh</a-button
        >

        <a-button
          @click.prevent="createSmartButton"
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          >Create</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- List Wrapper -->
    <div class="smart-buttons-list-wrapper">
      <!-- Loading -->
      <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No smart buttons -->
      <a-alert
        v-if="!isLoading && smartButtonsToShow.length == 0"
        type="info"
        message="No smart buttons to show"
      >
      </a-alert>
      <!-- / No smart buttons -->

      <!-- Loaded -->
      <div
        class="smart-buttons-list"
        v-if="!isLoading && smartButtonsToShow.length"
      >
        <a-row type="flex" :gutter="20">
          <a-col
            :span="colSpan"
            v-for="smartButton in smartButtonsToShow"
            :key="smartButton.id"
          >
          <smart-button-list-item
            :smart-button="smartButton"
            :tenant-id="tenantId"
            :org-id="selectedOrganisation.id"
            @selected="navigateToSmartButton(smartButton)"
          ></smart-button-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Loaded -->
    </div>
    <!-- / List Wrapper -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SmartButtonListItem from './SmartButtons/SmartButtonListItem.vue';
export default {
  components: { SmartButtonListItem },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.loadSmartButtons();
  },

  watch: {
    selectedOrganisation() {
      this.loadSmartButtons();
    },
  },

  methods: {
    ...mapActions("adminSmartButtons", {
      load: "load",
    }),

    loadSmartButtons() {
      this.load();
    },

    createSmartButton() {
      this.$router.push("/admin/integration/smart-buttons/create");
    },

    navigateToSmartButton(smartButton) {
      this.$router.push('/admin/integration/smart-buttons/' + smartButton.id);
    }
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    ...mapGetters("adminSmartButtons", {
      isLoading: "isLoading",
      triggerSources: "triggerSources"
    }),

    smartButtonsToShow() {
      return this.triggerSources;
    },

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },
  },
};
</script>

<style scoped lang="scss">
.smart-button-list-item {
  margin-bottom: 15px;
}

.button-margin-left {
  margin-left: 15px;
}
</style>