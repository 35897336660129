<template>
  <div class="smart-button-list-item" @click.prevent="$emit('selected')">
    <div class="left">
        <smart-button-thumbnail :tenant-id="tenantId" :smart-button="smartButton" size="large"></smart-button-thumbnail>
    </div>
    <div class="right">
        <div class="name">
            {{ smartButton.displayName }}
        </div>
        <div class="assigned-to">
            Assigned to {{ smartButton.presenceId ? getPresenceName(smartButton.presenceId) : 'organisation' }}
        </div>
        <div v-if="triggerFormFactor">
          {{ triggerFormFactor.displayName }}
        </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SmartButtonThumbnail from './SmartButtonListItem/SmartButtonThumbnail.vue';
const _ = require('lodash');
export default {
  components: { SmartButtonThumbnail },
  props: ["smartButton", "tenantId", "orgId"],
  computed: {
    ...mapGetters('admin', {
      membership: 'membership'
    }),

    ...mapGetters('adminSmartButtons', {
      triggerFormFactors: 'triggerFormFactors'
    }),

    triggerFormFactor() {
      if (this.triggerFormFactors && this.triggerFormFactors.length) {
        return _.find(this.triggerFormFactors, {
          id: this.smartButton.formFactorId
        });
      }
      return null;
    }
  },

  methods: {
    getPresenceName(presenceId) {
      let presence = _.find(this.membership, {id: presenceId});
      if (presence) {
        return presence.displayName;
      }
      return 'Unknown';
    }
  }
};
</script>

<style scoped lang="scss">
.smart-button-list-item {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #fff;

  display: flex;

  .left {
    flex-shrink: 1;
    display: flex;
    align-items: center;

    min-width: 45px;
    text-align: center;
  }

  .right {
    padding-left: 15px;
    flex-grow: 1;
  }

  &:hover {
    color: #5155ea;
    border: 1px solid #5155ea;
  }

  .name {
    font-weight: 500;
  }

  .name, .assigned-to {
    margin-bottom: 2px;
  }
}
</style>